import React, { useMemo, useCallback, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { trackEvent, getWindow } from '@surfline/web-common';

import FavoritesCarouselContent from 'components/FavoritesCarousel/FavoritesCarousel';
import VideoPlayer from 'components/VideoPlayer';
import config from 'config';
import { PageContext } from 'contexts/PageContext';
import {
  useMaxWidthDesktop,
  useMaxWidthDesktopSmall,
  useMaxWidthTablet,
  useMinWidthMobileLarge,
} from 'hooks/useMediaQueries';

import { AnonHeroLogo, AnonHeroBackground } from './AnonHeroSVGs';
import useAnonymousHeroColorScheme from './useAnonymousHeroColorScheme';

import styles from './AnonHero.module.scss';

interface AnonHeroProps {
  userCountryCode?: string;
  geoTarget: string;
  makeContextualHref: Function;
  colorSchemeIndex?: number;
}

const AnonHero: React.FC<AnonHeroProps> = ({
  userCountryCode,
  geoTarget,
  makeContextualHref,
  colorSchemeIndex,
}) => {
  const isMobileLarge = useMinWidthMobileLarge();
  const isDesktop = !useMaxWidthTablet();
  const isDesktopSmall = !useMaxWidthDesktopSmall();
  const isDesktopLarger = !useMaxWidthDesktop();
  const window = getWindow();
  const { heroColorScheme: colorScheme } = useAnonymousHeroColorScheme(colorSchemeIndex);
  const { pageName } = useContext(PageContext);
  const router = useRouter();

  const ctaText = 'Get started';
  const subHeadline = 'Cams. Forecasts. Reports. Travel. Stories.';
  const headline = 'All things surf, all in one place.';

  const getVideoInfoFromGeo = useCallback(() => {
    let videoSize = '360p';
    if (isMobileLarge) videoSize = '720p';
    if (isDesktopLarger) videoSize = '1080p';

    const heroVideos = {
      US: {
        poster: `${config.cdnUrl}/kbyg/videos/thumbnails/surfline-cams-us.jpg`,
        videos: [
          {
            src: `${config.cdnUrl}/kbyg/videos/surfline-cams-us-${videoSize}.mp4`,
            type: 'video/mp4',
          },
        ],
      },
      ANZ: {
        poster: `${config.cdnUrl}/kbyg/videos/thumbnails/surfline-cams-au.jpg`,
        videos: [
          {
            src: `${config.cdnUrl}/kbyg/videos/surfline-cams-au-${videoSize}.mp4`,
            type: 'video/mp4',
          },
        ],
      },
      EU: {
        poster: `${config.cdnUrl}/kbyg/videos/thumbnails/surfline-cams-eu.jpg`,
        videos: [
          {
            src: `${config.cdnUrl}/kbyg/videos/surfline-cams-eu-${videoSize}.mp4`,
            type: 'video/mp4',
          },
        ],
      },
      DEFAULT: {
        poster: `${config.cdnUrl}/kbyg/videos/thumbnails/surfline-cams.jpg`,
        videos: [
          {
            src: `${config.cdnUrl}/kbyg/videos/surfline-cams-${videoSize}.mp4`,
            type: 'video/mp4',
          },
        ],
      },
    };

    if (geoTarget === 'US') return heroVideos.US;
    if (geoTarget === 'EU') return heroVideos.EU;
    if (geoTarget === 'AU' || geoTarget === 'NZ') return heroVideos.ANZ;

    return heroVideos.DEFAULT;
  }, [geoTarget, isDesktopLarger, isMobileLarge]);

  const callToActionButton = useMemo(() => {
    const buttonText = isDesktop ? 'Check the surf' : 'View all';

    return {
      isExternal: true,
      text: buttonText,
      link: '/surf-reports-forecasts-cams-map',
      isDark: colorScheme.isDark,
      onClickCallback: () =>
        trackEvent('Button Clicked', {
          buttonText,
          pageName,
          uiElement: 'Home hero',
        }),
    };
  }, [colorScheme, isDesktop, pageName]);

  const headerVariants = useMemo(() => {
    if (isDesktopLarger) return 'subHeadline';
    if (isDesktop) return 'callout1';

    return 'subHeadline';
  }, [isDesktop, isDesktopLarger]);

  const onClickGetStarted = useCallback(() => {
    trackEvent('Clicked CTA', {
      location: 'Home Hero',
      pageName,
      ctaText,
      category: 'subscription',
      path: router.asPath,
      url: `${window?.location.host}${router.asPath}`,
    });
  }, [router]);

  const contentCarouselProps = useMemo(
    () => ({
      bleedOnDesktop: false,
      callToActionButton,
      colors: {
        bg: 'transparent',
        skeletonBg: colorScheme?.details,
        skeletonItem: colorScheme?.background,
        headerText: colorScheme?.textColor,
        headerButton: colorScheme?.textColor,
        errorText: colorScheme?.textColor,
      },
      dataTest: 'anonymous-home-page-carousel',
      screenConfig: {
        mobile: 1.4,
        mobileMedium: 2,
        mobileLarge: 2.5,
        small: isDesktopSmall ? 4 : 3,
        medium: 5,
      },
      title: isDesktop ? 'View live surf reports and surf forecasts' : 'Live surf reports',
    }),
    [
      callToActionButton,
      colorScheme?.background,
      colorScheme?.details,
      colorScheme?.textColor,
      isDesktop,
      isDesktopSmall,
    ],
  );

  const segmentTracking = useCallback(
    (title, cardIndex) => ({
      carouselName: 'Home Nearby Spots',
      pageName,
      carouselItem: title,
      category: 'Home',
      positionNumber: cardIndex,
    }),
    [pageName],
  );

  const carouselItemConfig = useMemo(
    () => ({
      alwaysDisplaySmall: true,
      segmentTracking,
    }),
    [segmentTracking],
  );

  const videoInfo = getVideoInfoFromGeo();

  return (
    <Box
      className={classNames(styles.anonHeroWrapper, 'sl-section-full-bleed')}
      data-testid="anonymous-home-page-module"
      style={{ background: colorScheme?.background }}
    >
      <AnonHeroBackground
        fill={colorScheme.details}
        dataTest={`anon-hero-bg-${colorScheme.name}`}
      />
      <Box className={classNames(styles.anonHeroContent, 'sl-section-container')}>
        <Box className={styles.anonHeroVideoSection}>
          <Box className={styles.anonHeroVideoWrapper}>
            <VideoPlayer
              autoplay
              id="anonymous-home-page-video-player"
              loop
              muted
              poster={videoInfo.poster}
              skin="none"
              sources={videoInfo.videos}
              testId="anonymous-home-page-video-player"
            />
          </Box>
        </Box>
        <Box className={styles.anonHeaderSection}>
          <AnonHeroLogo
            fill={colorScheme?.details}
            dataTest={`anon-hero-logo-${colorScheme.name}`}
          />
          <Typography
            className={styles.anonHeaderHeadline}
            color={colorScheme?.details}
            component="h1"
            variant="headline"
          >
            {headline}
          </Typography>
          <Typography
            className={styles.anonHeroSubheadline}
            color={colorScheme?.textColor}
            variant={headerVariants}
          >
            {subHeadline}
          </Typography>
          <Box className={styles.anonHeaderButtonWrapper}>
            <Button
              data-testid="get-started-cta"
              className={styles.anonHeaderButton}
              fullWidth={false}
              href="/create-account"
              onClick={onClickGetStarted}
              size={isDesktopLarger ? 'large' : 'medium'}
              sx={{
                boxShadow: 0,
                backgroundColor: colorScheme.buttonBackground,
                color: colorScheme.buttonText,
                '&:hover': {
                  color: colorScheme.buttonBackground,
                  backgroundColor: colorScheme.buttonText,
                  boxShadow: 0,
                },
              }}
              variant="secondary"
            >
              {ctaText}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={styles.anonHeroMobileDivider} />
      <Box className={styles.anonHeroCarouselWrapper}>
        <FavoritesCarouselContent
          carouselItemConfig={carouselItemConfig}
          contentCarouselProps={{ ...contentCarouselProps }}
          makeContextualHref={makeContextualHref}
          modalOpen={false}
          userCountryCode={userCountryCode}
        />
      </Box>
    </Box>
  );
};

export default AnonHero;
