import React, { useEffect, useContext, useRef, useMemo } from 'react';
import { useUnmount } from 'react-use';
import type { VideoJsPlayer } from 'video.js';

import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';
import { VideoJSContext } from 'contexts/VideoJSContext';

type PlayerSkins = 'default' | 'minimal' | 'none';

interface VideoPlayerContentProps {
  aspectRatio?: string;
  autoplay?: boolean;
  className?: string;
  id: string;
  loop?: boolean;
  muted?: boolean;
  playsinline?: boolean;
  poster: string;
  skin?: PlayerSkins;
  sources: { src: string; type: string }[];
  testId?: string;
}

const VideoPlayerContent: React.FC<VideoPlayerContentProps> = ({
  aspectRatio = '16:9',
  autoplay = false,
  className,
  id,
  loop = false,
  muted = false,
  playsinline = true,
  poster,
  skin,
  sources,
  testId = 'video-player',
}) => {
  const { videoJsLoaded } = useContext(VideoJSContext);
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  useEffect(() => {
    if (videoJsLoaded && videoRef?.current && !!window?.videojs) {
      let player;
      if (!playerRef.current) {
        const videoElement = window.document.createElement('video-js');
        videoElement.setAttribute('id', id);
        videoRef.current.appendChild(videoElement);

        player = window.videojs(videoElement, {
          aspectRatio,
          autoplay,
          controls: skin !== 'none',
          disablePictureInPicture: true,
          loop,
          muted,
          playsinline,
          poster,
          preload: 'auto',
          sources,
        });
        player.addClass('vjs-big-play-centered vjs-sl-cam-player');
        playerRef.current = player;
      } else {
        player = playerRef.current;
        player.removeClass(
          'vjs-sl-cam-player-minimal vjs-sl-cam-player-none vjs-sl-cam-player-default',
        );
        if (skin === 'none') {
          player.controls(false);
        } else {
          player.controls(true);
        }
        player.autoplay(autoplay);
        player.src(sources);
      }
      // set appropriate skin class
      switch (skin) {
        case 'minimal':
          player.addClass('vjs-sl-cam-player-minimal');
          break;
        case 'none':
          player.addClass('vjs-sl-cam-player-none');
          break;
        default:
          player.addClass('vjs-sl-cam-player-default');
          break;
      }
    }
  }, [aspectRatio, autoplay, id, loop, muted, playsinline, poster, skin, sources, videoJsLoaded]);

  useUnmount(() => {
    const player = playerRef?.current;
    if (player && !player.isDisposed()) {
      try {
        player.dispose();
        playerRef.current = null;
      } catch (error) {
        console?.error(error);
      }
    }
  });

  return <div className={className} data-testid={testId} ref={videoRef} />;
};

export interface VideoPlayerProps extends VideoPlayerContentProps {
  error?: { status: number; name: string; message: string };
}

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const { error, testId } = props;
  const dataTestId = useMemo(() => (testId ? `${testId}-error` : 'video-player-error'), [testId]);

  return (
    <ErrorBoundary error={error} render={() => <ErrorMessage dataTestId={dataTestId} />}>
      <VideoPlayerContent {...props} />
    </ErrorBoundary>
  );
};

export default VideoPlayer;
