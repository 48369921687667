import React, { ReactNode } from 'react';
import MUIModal from '@mui/material/Modal';
import { Button, Slide, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import Styles from './ModalContextual.module.scss';

export interface ModalContextualProps {
  children: ReactNode;
  open: boolean;
  returnHref: string;
}

export const ModalContextual = ({ children, open, returnHref }: ModalContextualProps) => {
  const router = useRouter();

  const handleClose = () => {
    router.push(returnHref, undefined, { shallow: true });
  };

  return (
    <MUIModal
      className="wavetrak-ad-ignore"
      open={open}
      onClose={handleClose}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
      data-testid="modal-contextual"
      keepMounted
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={Styles.modalContextual}>
          <header className={Styles.header} data-testid="modal-contextual-header">
            <Typography variant="body" color="text.secondary" className={Styles.headerHint}>
              Hint: Hit <span style={{ color: 'white' }}>esc</span> to exit
            </Typography>
            <Button
              onClick={handleClose}
              className={Styles.headerClose}
              data-testid="modal-contextual-close"
              variant="text"
            >
              Close X
            </Button>
          </header>
          <section className={Styles.content} data-testid="modal-contextual-content">
            {children}
          </section>
        </div>
      </Slide>
    </MUIModal>
  );
};

export default ModalContextual;
