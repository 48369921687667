import { useEffect } from 'react';
import { trackNavigatedToPage } from '@surfline/web-common';

/**
 * @description Sends a page call event to segment.
 * @param {String} pageName
 * @param {Object} properties A memoized version of the properties object
 * **do not define inline or else the page call will fire every re-render**
 */
export function usePageCall(pageName, properties, rendered = true) {
  useEffect(() => {
    if (rendered) {
      trackNavigatedToPage(pageName, {
        ...properties,
      });
    }
  }, [pageName, properties, rendered]);
}
