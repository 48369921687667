import { useMemo } from 'react';
import { useTheme } from '@mui/material';

const useAnonymousHeroColorScheme = (colorSchemeIndex: number | undefined) => {
  const theme = useTheme();

  const defaultColorScheme = useMemo(
    () => ({
      isDark: false,
      name: 'default',
      background: theme.expressive.aquaMan,
      details: theme.expressive.indo,
      textColor: theme.palette.common.white,
      buttonBackground: theme.palette.text.brandSecondary,
      buttonText: theme.palette.common.white,
    }),
    [
      theme.expressive.aquaMan,
      theme.expressive.indo,
      theme.palette.common.white,
      theme.palette.text.brandSecondary,
    ],
  );

  const heroColorScheme = useMemo(() => {
    switch (colorSchemeIndex || Math.floor(Math.random() * 6)) {
      case 0:
        return {
          isDark: false,
          name: 'barneys',
          background: theme.expressive.cantaloupe,
          details: theme.expressive.barneys,
          textColor: theme.palette.text.brandSecondary,
          buttonBackground: theme.palette.text.brandSecondary,
          buttonText: theme.palette.common.white,
        };
      case 1:
        return {
          isDark: true,
          name: 'indo',
          background: theme.expressive.deepSea,
          details: theme.expressive.indo,
          textColor: theme.palette.common.white,
          buttonBackground: theme.palette.common.white,
          buttonText: theme.palette.text.brandSecondary,
        };
      case 2:
        return {
          isDark: false,
          name: 'marineLayer',
          background: theme.expressive.bronzing,
          details: theme.expressive.marineLayer,
          textColor: theme.palette.text.brandSecondary,
          buttonBackground: theme.palette.text.brandSecondary,
          buttonText: theme.palette.common.white,
        };
      case 3:
        return {
          isDark: true,
          name: 'deepSea',
          background: theme.expressive.coral,
          details: theme.expressive.deepSea,
          textColor: theme.palette.common.white,
          buttonBackground: theme.palette.common.white,
          buttonText: theme.palette.text.brandSecondary,
        };
      case 4:
        return {
          isDark: true,
          name: 'abalone',
          background: theme.expressive.abalone,
          details: theme.expressive.deepSea,
          textColor: theme.palette.common.white,
          buttonBackground: theme.palette.common.white,
          buttonText: theme.palette.text.brandSecondary,
        };
      case 5:
        return {
          isDark: true,
          name: 'greenFlash',
          background: theme.expressive.aquaMan,
          details: theme.expressive.greenFlash,
          textColor: theme.palette.common.white,
          buttonBackground: theme.palette.common.white,
          buttonText: theme.palette.text.brandSecondary,
        };
      default:
        return defaultColorScheme;
    }
  }, [
    colorSchemeIndex,
    defaultColorScheme,
    theme.expressive.abalone,
    theme.expressive.aquaMan,
    theme.expressive.barneys,
    theme.expressive.bronzing,
    theme.expressive.cantaloupe,
    theme.expressive.coral,
    theme.expressive.deepSea,
    theme.expressive.greenFlash,
    theme.expressive.indo,
    theme.expressive.marineLayer,
    theme.palette.common.white,
    theme.palette.text.brandSecondary,
  ]);

  return {
    heroColorScheme,
    defaultColorScheme,
  };
};

export default useAnonymousHeroColorScheme;
