import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Card, CardMedia, Chip, Link, Typography } from '@mui/material';
import CloudflareResizedImage from 'components/CloudflareResizedImage';
import { trackEvent } from '@surfline/web-common';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import { PlayIcon } from '../Icons';
import styles from './EditorialCard.module.scss';

export interface EditorialCardProps {
  title?: string;
  imageUrl: string;
  link: string;
  lazyRoot?: React.RefObject<HTMLDivElement>;
  tag: string;
  isVideo?: boolean;
  cardIndex: number;
  id: number;
}

const EditorialCard: React.FC<EditorialCardProps> = ({
  title,
  imageUrl,
  link,
  lazyRoot,
  tag,
  isVideo = false,
  cardIndex,
  id,
}) => {
  const isMobile = useMaxWidthTablet();
  const router = useRouter();

  const onClickHandler = useCallback(
    () =>
      trackEvent('Carousel Clicked', {
        carouselItem: title,
        carouselName: 'Editorial Carousel',
        category: 'editorial',
        path: router.asPath,
        positionNumber: cardIndex,
        postId: id,
        postCategory: tag,
        url: `${window?.location.host}${router.asPath}`,
        title: 'Home',
      }),
    [title, router.asPath, cardIndex],
  );

  return (
    <div className={styles.editorialCardContainer}>
      <Card
        className={styles.editorialCard}
        onClick={onClickHandler}
        data-testid={`editorialCard-${cardIndex}`}
      >
        {isVideo && <PlayIcon className={styles.videoIcon} />}
        <Link className={styles.editorialCardLink} href={link} underline="none">
          <div className={styles.textContainer}>
            {title && (
              <Typography
                variant={isMobile ? 'subHeadline' : 'title3'}
                component="h3"
                className={styles.editorialCardTitle}
              >
                {title}
              </Typography>
            )}
            <Chip
              data-testid="EditorialCard-chip"
              label={
                <Typography color="brand.secondary" variant="callout1">
                  {tag}
                </Typography>
              }
              size="small"
              className={styles.chip}
            />
          </div>
        </Link>
        <CardMedia className={styles.editorialCardImage}>
          <CloudflareResizedImage
            src={imageUrl}
            alt={title}
            loading="lazy"
            objectFit="cover"
            objectPosition="center"
            layout="fill"
            lazyRoot={lazyRoot || null}
            sizes="(max-width: 768px) 340px,
             ((min-width: 769px) and (max-width: 1319px)) 388px,
             (min-width: 1320px) 521px"
            quality={100}
            cloudflareOptions={{ fit: 'cover', aspectRatio: 0.85 }}
          />
        </CardMedia>
      </Card>
    </div>
  );
};

export default EditorialCard;
