import React, { ReactNode } from 'react';

import classNames from 'classnames';
import styles from './EventsAndAlerts.module.scss';

interface EventsAndAlertsProps {
  children: ReactNode;
}

const EventsAndAlerts: React.FC<EventsAndAlertsProps> = ({ children }) => (
  <div
    className={classNames(styles.background, 'sl-section-full-bleed')}
    data-testid="events-and-alerts-section"
  >
    <div className={classNames(styles.container, 'sl-section-container')}>
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  </div>
);

export default EventsAndAlerts;
