import React from 'react';
import MetaTags from '../MetaTags';
import config from '../../config';

interface Props {}

const HomePageMeta: React.FC<Props> = () => {
  const pageTitle = config.meta.title.homepage;
  const metaDescription = config.meta.description.homepage;

  return <MetaTags title={pageTitle} description={metaDescription} urlPath="/" />;
};

export default HomePageMeta;
