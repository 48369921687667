import React from 'react';
import { Box } from '@mui/material';
import styles from './AnonHero.module.scss';

export const AnonHeroBackground = ({
  fill,
  dataTest,
}: {
  fill: string | undefined;
  dataTest: string;
}) => (
  <Box className={styles.anonHeroBackgroundSVG} data-testid={dataTest}>
    <svg
      width="543"
      height="707"
      viewBox="0 0 543 707"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.desktopSVG}
    >
      <path
        d="M479.259 132.139C394.371 6.55107 19.8857 -255.209 19.8857 -255.209C19.8857 -255.209 -179.293 156.056 -192.098 307.14C-195.534 347.688 -192.264 389.505 -181.422 431.117C-130.18 627.794 70.7498 745.734 267.327 694.518C463.903 643.302 581.74 442.311 530.498 245.634C519.635 203.941 502.085 165.845 479.259 132.139Z"
        fill={fill}
      />
    </svg>
    <svg
      width="256"
      height="971"
      viewBox="0 0 256 971"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.mobileSVG}
    >
      <path
        d="M731.912 546.617C708.84 396.797 482.146 0.101814 482.146 0.101814C482.146 0.101814 126.31 286.792 50.1641 417.911C29.7286 453.101 14.8135 492.304 6.83127 534.558C-30.8966 734.269 100.355 926.767 299.964 964.475C499.572 1002.18 692.003 870.834 729.731 671.123C737.729 628.787 738.143 586.844 731.912 546.617Z"
        fill={fill}
      />
    </svg>
  </Box>
);

export const AnonHeroLogo = ({
  fill,
  dataTest,
}: {
  fill: string | undefined;
  dataTest: string;
}) => (
  <Box className={styles.anonHeaderLogo} data-testid={dataTest}>
    <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3156 15.998C21.6688 11.3376 11.9998 0 11.9998 0C11.9998 0 2.32952 11.3389 0.682672 16.0007C0.240702 17.2518 0 18.5981 0 20.0002C0 26.627 5.373 32 11.9998 32C18.6267 32 23.9997 26.627 23.9997 20.0002C23.9997 18.5954 23.759 17.2491 23.3156 15.998ZM5.99991 21.9979C4.89567 21.9979 3.99949 21.1031 3.99949 19.9975C3.99949 15.5791 7.58149 11.9985 11.9985 11.9985C12.6893 11.9985 13.3597 12.0869 13.9989 12.2501C10.5488 13.1381 7.99898 16.2713 7.99898 19.9975C8.00034 21.1031 7.10552 21.9979 5.99991 21.9979ZM11.9998 27.9978C11.309 27.9978 10.6386 27.9108 9.9994 27.7462C13.4495 26.8582 15.9993 23.7263 15.9993 19.9988C15.9993 18.8946 16.8941 17.9984 17.9997 17.9984C19.104 17.9984 20.0002 18.8932 20.0002 19.9988C20.0002 24.4172 16.4182 27.9978 11.9998 27.9978Z"
        fill={fill}
      />
    </svg>
  </Box>
);

export default AnonHeroBackground;
