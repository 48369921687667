import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { getUserFavorites, trackEvent } from '@surfline/web-common';

import ControlBarScrollButtons from 'components/ControlBarScrollButtons';
import ForecastDayTable from 'components/ForecastDayTable';
import MissingFavourites from 'components/MissingFavourites';
import { ForecastOutlookContext } from 'contexts/ForecastOutlookContext';
import { PageContext } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import useFetchUserFavorites from 'hooks/useFetchUserFavorites';
import type { UserFavoritesSWRResponse } from 'types/userFavorites';

import styles from './FavoritesForecastOutlook.module.scss';

interface Props {
  makeContextualHref: Function;
  geotarget: string;
}

const defaultMissingArgs = {
  title: 'Surf Forecast',
  boldText: 'Looks a little empty in here.',
  normalText: 'Add some favorite spots to compare live conditions and forecasts.',
};

const linkAs = '/favorites?type=forecast';

const FavoritesForecastOutlook: React.FC<Props> = ({ makeContextualHref, geotarget }) => {
  const router = useRouter();
  const { userType } = useContext(UserTypeContext);
  const { pageName } = useContext(PageContext);
  const { forecasts, requestXForecasts } = useContext(ForecastOutlookContext);
  const favorites = useFetchUserFavorites(userType, geotarget) as UserFavoritesSWRResponse;
  const selectorFavorites = useSelector(getUserFavorites, shallowEqual);

  const link = useMemo(() => makeContextualHref({ type: 'forecast' }), [makeContextualHref]);
  const error = !!favorites.error;
  const favoritesData = favorites.data?.data?.favorites;
  const [noFavorites, setNoFavorites] = useState(favoritesData ? favoritesData.length < 1 : false);

  const scrollRef = useRef(null);

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(
        (scrollDirection: string, action: string) => {
          trackEvent('Forecast Range Updated', {
            action,
            pageName,
            scrollDirection,
            uiElement: 'forecast outlook',
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        },
      ),
    [pageName],
  );

  const onViewAllClicked = () =>
    trackEvent('Clicked Link', {
      linkText: 'View All',
      pageName,
      linkLocation: 'Outlook',
      linkName: 'View All',
      path: router.asPath,
      url: `${window?.location.host}${router.asPath}`,
    });

  const forecastDayTableProps = useMemo(() => {
    let filteredForecasts;
    if (userType.isAnonymous) {
      filteredForecasts = forecasts?.data?.overview?.slice(0, 5);
    } else {
      const isForecastDataAvailable = (forecasts?.data?.overview?.length ?? 0) > 0;
      const isFavoritesDataAvailable = (selectorFavorites?.length ?? 0) > 0;
      if (isForecastDataAvailable && isFavoritesDataAvailable) {
        filteredForecasts = selectorFavorites
          ?.reduce((acc: any[], fav: any) => {
            const forecast = forecasts?.data?.overview?.find(
              (forecastOverview) => fav._id === forecastOverview.spotId,
            );
            if (forecast) {
              acc.push(forecast);
            }
            return acc;
          }, [])
          .slice(0, 5);
      }
    }

    return {
      scrollRef,
      forecasts: filteredForecasts,
      units: forecasts?.associated?.units || { waveHeight: 'ft' },
      error:
        favorites.error ||
        (forecasts?.data.overview?.length !== 0 &&
          forecasts?.data.overview?.every((overviewRow) => !!overviewRow.error)),
    };
  }, [
    favorites.error,
    forecasts?.associated?.units,
    forecasts?.data.overview,
    selectorFavorites,
    userType.isAnonymous,
  ]);

  useEffect(() => {
    if (noFavorites && favoritesData && favoritesData?.length >= 1) {
      setNoFavorites(false);
    }
  }, [noFavorites, favoritesData]);

  useEffect(() => {
    requestXForecasts(favoritesData?.slice(0, 5), 5);
  }, [requestXForecasts, favoritesData, forecasts?.data, selectorFavorites]);

  return noFavorites && !error ? (
    <section className="sl-section-container">
      <MissingFavourites
        title={defaultMissingArgs.title}
        boldText={defaultMissingArgs.boldText}
        normalText={defaultMissingArgs.normalText}
      />
    </section>
  ) : (
    <section
      className={classNames(styles.outlook, 'sl-section-container')}
      data-testid="sl-favorites-forecast-outlook-container"
    >
      {!noFavorites && (
        <>
          <div className={styles.headerWrap}>
            <Typography variant="h2">Surf Forecast</Typography>
            <ControlBarScrollButtons
              callToActionButton={{
                text: 'View All',
                link,
                linkAs,
                onClickCallback: onViewAllClicked,
              }}
              scrollByPercent={80}
              shouldScroll
              buttonColor="#171717"
              scrollRef={scrollRef.current}
              debouncedScrollHandlers={{
                left: (action: string) => debouncedScrollHandler('Left', action),
                right: (action: string) => debouncedScrollHandler('Right', action),
              }}
            />
          </div>
          <ForecastDayTable {...forecastDayTableProps} />
        </>
      )}
    </section>
  );
};

export default FavoritesForecastOutlook;
