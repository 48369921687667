import { doFetch } from 'common/baseFetch';
import { SwellEvents } from 'types/swellEvents';

const swellEventsFetch = async (swellEventsFetchUrl: string, options = {}) => {
  const { events } = (await doFetch(swellEventsFetchUrl, options)) as {
    events: SwellEvents;
  };
  return events;
};

export default swellEventsFetch;
